
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import Tablerow from "@/components/FixedTaxReduction/manager/table/Row.vue";

  @Component({
    components: {
      Tablerow,
    },
  })
  export default class Table extends Mixins(mixinFixedTaxReduction) {
    get table_column() {
      return this.payment_and_bonuses.length * 3;
    }
    created() {
      this.set_sort("code", "asc");
    }
  }
