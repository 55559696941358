
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import CommonToggle from "@/components/common/Toggle.vue";

  import CommonSelect from "@/components/common/Select.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonToggle,

      CommonSelect,
      CommonButton,
    },
  })
  export default class Row extends Mixins(mixinFixedTaxReduction) {
    @Prop() fixed_tax_reduction_master;
    @Prop() fixed_tax_reduction_histories;

    go_to_fixed_tax_reduction_employee() {
      this.change_page("fixed_tax_reduction_employee", {
        fixed_tax_reduction_id: this.fixed_tax_reduction_id,
        employee_id: this.fixed_tax_reduction_master.employee_id,
        is_finish_employee: undefined,
      });
    }

    get is_valid_fixed_tax_reduction() {
      if (this.fixed_tax_reduction_master.is_otsu) {
        return false;
      } else {
        return this.fixed_tax_reduction_master.is_valid == 1;
      }
    }
    set is_valid_fixed_tax_reduction(val) {
      this.m.updateFixedTaxReductionMasterValid({
        fixed_tax_reduction_id: this.fixed_tax_reduction_master.fixed_tax_reduction_id,
        branch_id: this.viewing_branch,
        employee_id: this.fixed_tax_reduction_master.employee_id,
        is_valid: val,
      });
    }

    get completed_fixed_tax_reduction_master() {
      return this.fixed_tax_reduction_master.is_finish_employee == 1;
    }

    send_reminder() {
      this.m.sendFixedTaxReductionReminder({
        employee_id: this.fixed_tax_reduction_master.employee_id,
        fixed_tax_reduction_id: this.fixed_tax_reduction_master.fixed_tax_reduction_id,
        employee_name: this.employee_hash[this.fixed_tax_reduction_master.employee_id].name,
      });
    }
  }
