
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import CommonPager from "@/components/common/Pager.vue";

  @Component({
    components: {
      CommonPager,
    },
  })
  export default class Monthchanger extends Mixins(mixinFixedTaxReduction) {
    get yearstr() {
      if (this.global) {
        return `${this.fixed_tax_reduction.year}年`;
      } else {
        return "読み込み中";
      }
    }
  }
