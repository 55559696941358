
  import { Component, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import BranchSelector from "@/components/FixedTaxReduction/manager/table/Branchselector.vue";
  import PagerHead from "@/components/FixedTaxReduction/manager/pager/Head.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      BranchSelector,
      PagerHead,
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinFixedTaxReduction) {
    delete_fixed_tax_reduction() {
      this.m.deleteFixedTaxReduction({ fixed_tax_reduction_id: this.fixed_tax_reduction_id });
    }
    edit_fixed_tax_reduction_masters() {
      this.is_open = true;

      this.fixed_tax_reduction_masters.forEach((fixed_tax_reduction_master) => {
        if (fixed_tax_reduction_master.reflected_to === null) {
          const fixed_tax_reduction_master_object = util.deep_copy(fixed_tax_reduction_master);

          if (
            fixed_tax_reduction_master.is_custom_pay_sum ||
            fixed_tax_reduction_master.is_custom_ins_sum ||
            fixed_tax_reduction_master.is_custom_tax_sum
          ) {
            fixed_tax_reduction_master_object["is_pay_sum_mode"] = true;
            fixed_tax_reduction_master_object["is_ins_sum_mode"] = true;
            fixed_tax_reduction_master_object["is_tax_sum_mode"] = true;
            for (let i = 1; i <= 12; i++) {
              fixed_tax_reduction_master_object[`pay_payment_${i}`] = null;
              fixed_tax_reduction_master_object[`ins_payment_${i}`] = null;
              fixed_tax_reduction_master_object[`tax_payment_${i}`] = null;
            }
            for (let i = 1; i <= 4; i++) {
              fixed_tax_reduction_master_object[`pay_bonus_${i}`] = null;
              fixed_tax_reduction_master_object[`ins_bonus_${i}`] = null;
              fixed_tax_reduction_master_object[`tax_bonus_${i}`] = null;
            }
          } else {
            fixed_tax_reduction_master_object["is_pay_sum_mode"] = false;
            fixed_tax_reduction_master_object["is_ins_sum_mode"] = false;
            fixed_tax_reduction_master_object["is_tax_sum_mode"] = false;
          }
          this.m.setFixedTaxReductionMaster({
            employee_id: fixed_tax_reduction_master.employee_id,
            fixed_tax_reduction_master_object,
          });
        }
      });

      this.m.editFixedTaxReductionMasters();
    }
    update_fixed_tax_reduction_masters() {
      this.m.updateFixedTaxReductionMasters({
        fixed_tax_reduction_id: this.fixed_tax_reduction.id,
        branch_id: this.viewing_branch,
        employee_hash: this.yearly.employee_hash,
      });
    }

    updateFixedTaxReductionMastersEmployee() {
      this.m.updateFixedTaxReductionMastersEmployee({
        fixed_tax_reduction_id: this.fixed_tax_reduction.id,
        branch_id: this.viewing_branch,
        branch_name: this.branch_hash[this.viewing_branch],
      });
    }

    fixed_tax_reduction_csv() {
      this.m.fixed_tax_reduction_csv({
        branch_id: this.viewing_branch,
        fixed_tax_reduction_id: this.fixed_tax_reduction.id,
        year: this.fixed_tax_reduction.year,
      });
    }
  }
