import { Component, Mixins } from "vue-property-decorator";
import yearly from "@/vuex/fixed_tax_reduction/yearly";
import employee_yearly from "@/vuex/fixed_tax_reduction/employee_yearly";
import auth from "@/vuex/employee_each/auth";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/fixed_tax_reduction/manager";
import employee_manager from "@/vuex/employee_each/manager";
import { BranchHeaderData } from "@/typesold/header";
import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";

@Component
export default class mixinFixedTaxReduction extends Mixins(utilMixins) {
  changed_disp_num = false;

  get newm() {
    return vuex_manager.fixed_tax_reduction_new_manager;
  }

  get global() {
    return vuex_data.fixed_tax_reduction_global.get(this);
  }
  get global_latest() {
    return vuex_data.fixed_tax_reduction_global.getLatest();
  }

  get is_set_global(): boolean {
    return !!this.global;
  }
  get is_set_yearly(): boolean {
    return (
      yearly.data[this.fixed_tax_reduction_id] &&
      yearly.data[this.fixed_tax_reduction_id][this.viewing_branch] &&
      yearly.data[this.fixed_tax_reduction_id][this.viewing_branch].is_set
    );
  }
  get is_set_employee_yearly(): boolean {
    return (
      employee_yearly.data[this.fixed_tax_reduction_id] &&
      employee_yearly.data[this.fixed_tax_reduction_id][this.employee_id] &&
      employee_yearly.data[this.fixed_tax_reduction_id][this.employee_id] &&
      employee_yearly.data[this.fixed_tax_reduction_id][this.employee_id].is_set
    );
  }
  get is_set_auth(): boolean {
    return auth.data[this.employee_id] && auth.data[this.employee_id].is_set;
  }

  get is_valid_global(): boolean {
    return !!this.global;
  }
  get is_valid_yearly(): boolean {
    return (
      yearly.data[this.fixed_tax_reduction_id] &&
      yearly.data[this.fixed_tax_reduction_id][this.viewing_branch] &&
      yearly.data[this.fixed_tax_reduction_id][this.viewing_branch].is_valid
    );
  }
  get is_valid_employee_yearly(): boolean {
    return (
      employee_yearly.data[this.fixed_tax_reduction_id] &&
      employee_yearly.data[this.fixed_tax_reduction_id][this.employee_id] &&
      employee_yearly.data[this.fixed_tax_reduction_id][this.employee_id].is_valid
    );
  }
  get is_valid_auth(): boolean {
    return auth.data[this.employee_id] && auth.data[this.employee_id].is_valid;
  }

  async get_yearly() {
    await yearly.fetch({
      branch_id: this.viewing_branch,
      fixed_tax_reduction_id: this.fixed_tax_reduction_id,
    });
  }
  async get_employee_yearly() {
    await employee_yearly.fetch({
      fixed_tax_reduction_id: this.fixed_tax_reduction_id,
      employee_id: this.employee_id,
    });
  }
  async get_auth() {
    await auth.fetch({ employee_id: this.employee_id });
  }

  get yearly() {
    if ((!this.is_set_yearly || !this.is_valid_yearly) && this.fixed_tax_reduction_id !== 0)
      this.get_yearly();
    if (this.is_set_yearly) return yearly.data[this.fixed_tax_reduction_id][this.viewing_branch];
  }
  get employee_yearly() {
    if (
      (!this.is_set_employee_yearly || !this.is_valid_employee_yearly) &&
      this.fixed_tax_reduction_id !== 0
    )
      this.get_employee_yearly();
    if (this.is_set_employee_yearly)
      return employee_yearly.data[this.fixed_tax_reduction_id][this.employee_id];
  }
  get auth() {
    if (!this.is_set_auth || !this.is_valid_auth) this.get_auth();
    if (this.is_set_auth) return auth.data[this.employee_id];
  }

  get employee_auth() {
    return this.auth.employee;
  }

  get fixed_tax_reduction_id() {
    console.log(this.global_latest.latest_id, this.param_fixed_tax_reduction_id);
    if (this.param_fixed_tax_reduction_id == 0) {
      return this.global_latest.latest_id;
    } else {
      return this.param_fixed_tax_reduction_id;
    }
  }
  set fixed_tax_reduction_id(val) {
    this.param_fixed_tax_reduction_id = val;
  }

  get employee_id() {
    if (this.is_manager) {
      return this.query.employee_id;
    } else {
      return this.s_employee_id;
    }
  }

  get fixed_tax_reduction() {
    return this.global.fixed_tax_reduction;
  }
  get payment_info() {
    return this.global.payment_info;
  }
  get bonus_info() {
    return this.global.bonus_info;
  }

  get viewing_branch() {
    let branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = util.getLocalStorage("branch_id");
      this.query = { viewing_branch: branch_id };
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.query = { viewing_branch: val, fixed_tax_reduction_page: 1 };
  }

  get m() {
    return manager;
  }
  get em() {
    return employee_manager;
  }

  get branch_hash_object() {
    return this.global.branch_hash_object;
  }

  get param_fixed_tax_reduction_id() {
    let fixed_tax_reduction_id = this.query.fixed_tax_reduction_id;
    if (fixed_tax_reduction_id === undefined) {
      fixed_tax_reduction_id = 0;
      this.query = { fixed_tax_reduction_id };
    }
    return fixed_tax_reduction_id;
  }
  set param_fixed_tax_reduction_id(val) {
    if (val != this.param_fixed_tax_reduction_id) {
      this.query_push = { fixed_tax_reduction_id: val };
    }
  }

  get search_key() {
    const search_key = this.query.fixed_tax_reduction_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { fixed_tax_reduction_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_search_key: undefined };
    } else {
      this.query = { fixed_tax_reduction_search_key: val };
    }
  }

  get is_open() {
    return this.query.is_open_fixed_tax_reduction;
  }
  set is_open(val) {
    if (val == "" || val == null) {
      this.query = { is_open_fixed_tax_reduction: undefined };
    } else {
      this.query = { is_open_fixed_tax_reduction: val };
    }
  }

  get sort_code() {
    return this.query.fixed_tax_reduction_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_sort_code: undefined };
    } else {
      this.query = { fixed_tax_reduction_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.fixed_tax_reduction_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_sort_name: undefined };
    } else {
      this.query = { fixed_tax_reduction_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.fixed_tax_reduction_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_sort_hiring_type: undefined };
    } else {
      this.query = { fixed_tax_reduction_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.fixed_tax_reduction_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_sort_branch_id: undefined };
    } else {
      this.query = { fixed_tax_reduction_sort_branch_id: val };
    }
  }
  get sort_self_info() {
    return this.query.fixed_tax_reduction_sort_self_info;
  }
  set sort_self_info(val) {
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_sort_self_info: undefined };
    } else {
      this.query = { fixed_tax_reduction_sort_self_info: val };
    }
  }

  get page() {
    let page = this.query.fixed_tax_reduction_page;
    if (page === undefined || page == "") {
      this.query = { fixed_tax_reduction_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { fixed_tax_reduction_page: undefined };
    } else {
      this.query = { fixed_tax_reduction_page: val };
    }
  }

  get searched_sorted_fixed_tax_reduction_masters() {
    let fixed_tax_reduction_masters = util.deep_copy(this.fixed_tax_reduction_masters);
    fixed_tax_reduction_masters = this.search_fixed_tax_reduction_masters(
      fixed_tax_reduction_masters,
    );
    fixed_tax_reduction_masters = this.sort_fixed_tax_reduction_masters(
      fixed_tax_reduction_masters,
    );
    return fixed_tax_reduction_masters;
  }
  get managed_fixed_tax_reduction_masters() {
    let fixed_tax_reduction_masters = util.deep_copy(
      this.searched_sorted_fixed_tax_reduction_masters,
    );
    fixed_tax_reduction_masters = this.page_fixed_tax_reduction_masters(
      fixed_tax_reduction_masters,
    );
    return fixed_tax_reduction_masters;
  }
  search_fixed_tax_reduction_masters(fixed_tax_reduction_masters) {
    if (this.search_key === null) {
      return fixed_tax_reduction_masters;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      return fixed_tax_reduction_masters.filter((fixed_tax_reduction_master) => {
        let flag = 0;
        search_keys.forEach((key) => {
          if (
            this.employee_hash[fixed_tax_reduction_master.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(
              this.employee_hash[fixed_tax_reduction_master.employee_id].kana,
              key,
            ) ||
            (this.employee_hash[fixed_tax_reduction_master.employee_id].code &&
              this.employee_hash[fixed_tax_reduction_master.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_fixed_tax_reduction_masters(fixed_tax_reduction_masters) {
    if (this.sort_code)
      fixed_tax_reduction_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      fixed_tax_reduction_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      fixed_tax_reduction_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      fixed_tax_reduction_masters.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    else if (this.sort_self_info)
      fixed_tax_reduction_masters.sort((x, y) =>
        util.sort_idx(
          x.option_selected ? (x.is_finish_employee ? 2 : 1) : 0,
          y.option_selected ? (y.is_finish_employee ? 2 : 1) : 0,
          this.sort_self_info,
        ),
      );

    return fixed_tax_reduction_masters;
  }
  page_fixed_tax_reduction_masters(fixed_tax_reduction_masters) {
    return fixed_tax_reduction_masters.slice(
      this.disp_num * (this.page - 1),
      this.disp_num * this.page,
    );
  }
  sort_click(sort_key) {
    this.page = 1;
    sort_key = `sort_${sort_key}`;
    let sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    this.query = {
      fixed_tax_reduction_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      fixed_tax_reduction_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      fixed_tax_reduction_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      fixed_tax_reduction_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,
      fixed_tax_reduction_sort_self_info: sort_key == "sort_self_info" ? sort_mode : undefined,
    };
  }

  get master_editable() {
    return this.yearly.editable;
  }
  get fixed_tax_reduction_masters() {
    return this.yearly.fixed_tax_reduction_masters;
  }
  get fixed_tax_reduction_master_employee() {
    return this.employee_yearly.fixed_tax_reduction_master;
  }
  get editable() {
    return this.employee_yearly.editable;
  }
  get payment_and_bonuses() {
    return this.yearly.payment_and_bonuses;
  }
  get fixed_tax_reduction_histories_hash() {
    console.log(this.yearly.fixed_tax_reduction_histories_hash);
    return this.yearly.fixed_tax_reduction_histories_hash;
  }

  get employee_hash() {
    return this.yearly.employee_hash;
  }
  get branch_hash() {
    return this.yearly.branch_hash;
  }
  get branch_hash_all() {
    return this.yearly.branch_hash_all;
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("fixed_tax_reduction_disp_num", val);
    manager.setDispNum(val);
  }

  get reflectable_fixed_tax_reduction() {
    const header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_fixed_tax_reduction_reflect == 1);
  }
  get editable_fixed_tax_reduction() {
    const header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_fixed_tax_reduction == 2);
  }
}
