
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import CommonProgress from "@/components/common/Progress.vue";
  import TableMain from "@/components/FixedTaxReduction/manager/table/Main.vue";

  @Component({
    components: {
      CommonProgress,
      TableMain,
    },
  })
  export default class Main extends Mixins(mixinFixedTaxReduction) {}
