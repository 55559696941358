
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinFixedTaxReduction) {
    create_nencho() {
      this.m.createFixedTaxReduction({
        payment_info: this.payment_info,
        bonus_info: this.bonus_info,
      });
    }
  }
