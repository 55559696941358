
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";

  @Component({
    components: {},
  })
  export default class Foot extends Mixins(mixinFixedTaxReduction) {
    get all_index() {
      return this.searched_sorted_fixed_tax_reduction_masters.length;
    }
    get start_index() {
      return this.disp_num * (this.page - 1) + 1;
    }
    get end_index() {
      return Math.min(this.all_index, this.disp_num * this.page);
    }
    get max_page() {
      return Math.ceil(this.all_index / this.disp_num);
    }
    get page_num_list() {
      let start_page = Math.max(1, this.page - 2);
      if (this.page > this.max_page - 2) start_page = Math.max(1, this.max_page - 4);
      let end_page = Math.min(this.max_page, this.page + 2);
      if (this.page < 3) end_page = Math.min(this.max_page, 5);
      const page_num_list = [];
      for (let i = start_page; i <= end_page; i++) {
        page_num_list.push(i);
      }
      return page_num_list;
    }
  }
