
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import NoFixedTaxReduction from "@/components/FixedTaxReduction/manager/NoFixedTaxReduction.vue";
  import MonthChanger from "@/components/FixedTaxReduction/manager/Monthchanger.vue";
  import Main from "@/components/FixedTaxReduction/manager/Main.vue";

  @Component({
    components: {
      NoFixedTaxReduction,
      MonthChanger,
      Main,
    },
  })
  export default class FixedTaxReduction extends Mixins(mixinFixedTaxReduction) {}
