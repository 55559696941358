
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import Control from "@/components/FixedTaxReduction/manager/table/Control.vue";
  import Table from "@/components/FixedTaxReduction/manager/table/Table.vue";
  import Foot from "@/components/FixedTaxReduction/manager/pager/Foot.vue";

  @Component({
    components: {
      Control,
      Table,
      Foot,
    },
  })
  export default class Main extends Mixins(mixinFixedTaxReduction) {}
