import Vue from "vue";
import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationFixedTaxReductionMasters } from "@/validations/fixed_tax_reduction";
import dialog from "@/vuex/dialog";
import router from "@/router";
import node from "@/vuex/node";
import vuex_manager from "@/vuex/vuex_manager";

@Module({ dynamic: true, store, name: "fixed_tax_reduction_manager", namespaced: true })
class Manager extends VuexModule {
  disp_num = 10;
  edit_fixed_tax_reduction_masters_flag = false;

  fixed_tax_reduction_masters = {};
  fixed_tax_reduction_master = {};

  step = 0.1;

  @Mutation
  public setStep(val) {
    this.step = val;
  }

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setEditFixedTaxReductionMastersFlag(false);
  }

  @Action({ rawError: true })
  public async is_open_input() {
    return this.edit_fixed_tax_reduction_masters_flag;
  }

  @Mutation
  private setEditFixedTaxReductionMastersFlag(val: boolean) {
    if (!val) {
      Vue.set(this, "fixed_tax_reduction_masters", {});
    }
    this.edit_fixed_tax_reduction_masters_flag = val;
  }

  @Action({ rawError: true })
  public createFixedTaxReduction() {
    dialog.openConfirmDialog({
      msg: "定額減税を開始してよろしいですか？",
      detail: "全従業員にメールが送信されます",
      cancel_button: "開始しない",
      exec_button: "開始する",
      func: this.createFixedTaxReductionProcess,
      args: {},
      is_next_dialog: true,
    });
  }
  @Action({ rawError: true })
  public async createFixedTaxReductionProcess() {
    dialog.openProgressDialog({ title: "開始中", msg: "この処理には時間がかかることがあります。" });
    await util.post("fixed_tax_reduction/create_fixed_tax_reduction", {});
    node.disable();
    dialog.openAlertDialog({ msg: "定額減税を開始しました" });
  }

  @Action({ rawError: true })
  public async editFixedTaxReductionMasters() {
    if (await this.is_open_input()) return;
    this.setEditFixedTaxReductionMastersFlag(true);
  }

  @Action({ rawError: true })
  public updateFixedTaxReductionMasters({
    fixed_tax_reduction_id,
    branch_id,
    employee_hash,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
    employee_hash: any;
  }) {
    if (
      validate(validationFixedTaxReductionMasters(this.fixed_tax_reduction_masters, employee_hash))
    ) {
      dialog.openConfirmDialog({
        msg: "定額減税情報を更新してよろしいですか？",
        cancel_button: "更新しない",
        exec_button: "更新する",
        func: this.updateFixedTaxReductionMastersProcess,
        is_next_dialog: true,
        args: { fixed_tax_reduction_id, branch_id },
      });
    }
  }
  @Action({ rawError: true })
  public async updateFixedTaxReductionMastersProcess({
    fixed_tax_reduction_id,
    branch_id,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "更新中", msg: "この処理には時間がかかることがあります。" });
    await util.post("fixed_tax_reduction/update_fixed_tax_reduction_masters", {
      fixed_tax_reduction_id,
      branch_id,
      fixed_tax_reduction_masters: this.fixed_tax_reduction_masters,
    });
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({ msg: "更新が完了しました" });
  }

  @Action({ rawError: true })
  public updateFixedTaxReductionMastersEmployee({
    fixed_tax_reduction_id,
    branch_id,
    branch_name,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
    branch_name: string;
  }) {
    let branch_name_str = `${branch_name}に主所属として`;
    if (branch_id == 0) {
      branch_name_str = "";
    }
    dialog.openConfirmDialog({
      msg: `定額減税開始後に${branch_name_str}入社した社員を追加してよろしいですか？`,
      cancel_button: "追加しない",
      exec_button: "追加する",
      func: this.updateFixedTaxReductionMastersEmployeeProcess,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id, branch_id },
    });
  }
  @Action({ rawError: true })
  public async updateFixedTaxReductionMastersEmployeeProcess({
    fixed_tax_reduction_id,
    branch_id,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
  }) {
    dialog.openProgressDialog({ title: "処理中", msg: "しばらくお待ちください" });
    await util.post("fixed_tax_reduction/update_fixed_tax_reduction_master_employee", {
      fixed_tax_reduction_id,
      branch_id,
    });
    this.cancelAll();
    node.disable();
    dialog.openAlertDialog({ msg: "完了しました。" });
  }

  @Mutation
  setFixedTaxReductionMaster({
    employee_id,
    fixed_tax_reduction_master_object,
  }: {
    employee_id: number;
    fixed_tax_reduction_master_object: any;
  }) {
    Vue.set(this.fixed_tax_reduction_masters, employee_id, fixed_tax_reduction_master_object);
  }
  @Mutation
  setFixedTaxReductionMasterOption({
    fixed_tax_reduction_master,
  }: {
    fixed_tax_reduction_master: any;
  }) {
    Vue.set(this, "fixed_tax_reduction_master", fixed_tax_reduction_master);
  }

  @Action({ rawError: true })
  public async updateFixedTaxReductionMasterValid({
    fixed_tax_reduction_id,
    branch_id,
    employee_id,
    is_valid,
  }: {
    fixed_tax_reduction_id: number;
    branch_id: number;
    employee_id: number;
    is_valid: boolean;
  }) {
    await util.post("fixed_tax_reduction/update_fixed_tax_reduction_master_valid", {
      fixed_tax_reduction_id,
      branch_id,
      employee_id,
      is_valid,
    });
  }

  @Action({ rawError: true })
  public async deleteFixedTaxReduction({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "定額減税を削除してよろしいですか？",
      detail:
        "定額減税に関する情報が全て削除されます。<br>ロック済みの給与・賞与の明細に記載されている金額は残ります。<br>一度削除すると、これらを元に戻すことはできません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "全て削除する",
      func: this.deleteFixedTaxReductionConfirm,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id },
    });
  }
  @Action({ rawError: true })
  public deleteFixedTaxReductionConfirm({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当に全て削除してよろしいですか？",
      detail:
        "定額減税に関する情報が全て削除されます。<br>ロック済みの給与・賞与の明細に記載されている金額は残ります。<br>一度削除すると、これらを元に戻すことはできません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "全て削除する",
      func: this.deleteFixedTaxReductionConfirmConfirm,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id },
    });
  }
  @Action({ rawError: true })
  public deleteFixedTaxReductionConfirmConfirm({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail: "一度削除すると、元に戻すことはできません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "全て削除する",
      func: this.deleteFixedTaxReductionProcess,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id },
    });
  }
  @Action({ rawError: true })
  public async deleteFixedTaxReductionProcess({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
    employee_id: number;
  }) {
    dialog.openProgressDialog({
      title: "削除中",
      msg: "この処理には時間がかかることがあります。",
    });
    await util.post("fixed_tax_reduction/delete_fixed_tax_reduction", { fixed_tax_reduction_id });
    node.disable();
    router.push({ name: "fixedtaxreduction" });
    dialog.closeDialog();
    dialog.openAlertDialog({ msg: "定額減税を削除しました。" });
  }

  @Action({ rawError: true })
  public sendFixedTaxReductionReminder({
    employee_id,
    fixed_tax_reduction_id,
    employee_name,
  }: {
    employee_id: number;
    fixed_tax_reduction_id: number;
    employee_name: string;
  }) {
    dialog.openConfirmDialog({
      msg: `${employee_name}さんへ定額減税催促通知を送信してよろしいですか？`,
      detail: "定額減税開始時と同じメール・アプリ通知が送信されます。",
      cancel_button: "送信しない",
      exec_button: "送信する",
      func: this.sendFixedTaxReductionReminderProcess,
      is_next_dialog: true,
      args: { employee_id, fixed_tax_reduction_id },
    });
  }
  @Action({ rawError: true })
  public async sendFixedTaxReductionReminderProcess({
    employee_id,
    fixed_tax_reduction_id,
  }: {
    employee_id: number;
    fixed_tax_reduction_id: number;
  }) {
    dialog.openProgressDialog({ title: "処理中", msg: "この処理には時間がかかることがあります。" });
    await util.post("fixed_tax_reduction/send_fixed_tax_reduction_reminder", {
      employee_id,
      fixed_tax_reduction_id,
    });
    dialog.openAlertDialog({ msg: "送信しました。" });
  }

  @Action({ rawError: true })
  public async updateFixedTaxReductionEmployee({
    fixed_tax_reduction_id,
    employee_id,
    employee,
    spouce,
    dependants,
    query,
  }: {
    fixed_tax_reduction_id: number;
    employee_id: number;
    employee: any;
    spouce: any;
    dependants: any;
    query: any;
  }) {
    dialog.openForceConfirmDialog({
      msg: "確定してよろしいですか？",
      cancel_button: "確定しない",
      exec_button: "確定する",
      func: this.updateFixedTaxReductionEmployeeProcess,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id, employee_id, employee, spouce, dependants, query },
    });
  }
  @Action({ rawError: true })
  public async updateFixedTaxReductionEmployeeProcess({
    fixed_tax_reduction_id,
    employee_id,
    employee,
    spouce,
    dependants,
    query,
  }: {
    fixed_tax_reduction_id: number;
    employee_id: number;
    employee: any;
    spouce: any;
    dependants: any;
    query: any;
  }) {
    router.push({
      name: "fixed_tax_reduction_employee",
      query: { ...query, is_finish_employee: "true" },
    });
    dialog.openProgressDialog({ title: "送信中", msg: "この処理には時間がかかることがあります。" });
    await util.post("fixed_tax_reduction/update_fixed_tax_reduction_employee", {
      fixed_tax_reduction_id,
      employee_id,
      employee,
      spouce,
      dependants,
    });
    vuex_manager.fixed_tax_reduction_new_manager.setState({ name: "step", val: 10 });
    dialog.closeDialog();
  }

  @Action({ rawError: true })
  public async updateForceFixedTaxReductionEmployee({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
  }) {
    console.log(fixed_tax_reduction_id);
    dialog.openForceConfirmDialog({
      msg: "本人情報の確認が未済の全社員を現在登録されている社員情報で確定しますか？",
      detail:
        "全社員の本人情報が6/1日時点のもので間違いないなく、全ての扶養親族等がARROWに登録されていることを確認してから実行してください。\n6/1時点の情報でない場合はマイページからの本人確認をお勧めします。",
      cancel_button: "確定しない",
      exec_button: "確定する",
      func: this.updateForceFixedTaxReductionEmployeeConfirm,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id },
    });
  }
  @Action({ rawError: true })
  public async updateForceFixedTaxReductionEmployeeConfirm({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail:
        "全社員の本人情報が6/1日時点のもので間違いないなく、全ての扶養親族等がARROWに登録されていることを確認してから実行してください。\n6/1時点の情報でない場合はマイページからの本人確認をお勧めします。",
      cancel_button: "確定しない",
      exec_button: "確定する",
      func: this.updateForceFixedTaxReductionEmployeeProcess,
      is_next_dialog: true,
      args: { fixed_tax_reduction_id },
    });
  }
  @Action({ rawError: true })
  public async updateForceFixedTaxReductionEmployeeProcess({
    fixed_tax_reduction_id,
  }: {
    fixed_tax_reduction_id: number;
  }) {
    dialog.openProgressDialog({ title: "送信中", msg: "この処理には時間がかかることがあります。" });
    await util.post("fixed_tax_reduction/force_update_fixed_tax_reduction_employee", {
      fixed_tax_reduction_id,
    });
    dialog.openAlertDialog({ msg: "社員情報の確定が完了しました。" });
  }
}

export default getModule(Manager);
